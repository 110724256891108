import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

// Create a theme instance with responsive font sizes.
let CustomTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: grey[50],
      },
      secondary: {
        light: grey[500],
        main: grey[900],
      },
    },
    fireworkRed: '#dc3546',
  }),
);

export default CustomTheme;
