import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Card } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    height: "30%",
    padding: "0.7%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  banner: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      minWidth: 100,
    },
    flex: 5,
    backgroundPosition: "left",
    minHeight: 150,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 25,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
    },
    alignSelf: "center",
  },
  cardAction: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Slider(props) {
  const classes = useStyles();
  return (
    <Card className={classes.card} style={{ backgroundColor: "gray" }}>
      <Skeleton
        animation="wave"
        variant="rect"
        className={classes.banner}
        alt="slider image"
      />
      <div className={classes.textContainer}>
        <Typography
          component={"span"}
          variant="h4"
          gutterBottom
          color="inherit"
        >
          <Skeleton animation="wave" variant="text" />
          <Skeleton animation="wave" variant="text" />
        </Typography>
        <Typography
          component={"span"}
          variant="body1"
          gutterBottom
          color="inherit"
        >
          <Box lineHeight={1.2} fontWeight="fontWeightMedium">
            <Skeleton animation="wave" variant="text" />
          </Box>
        </Typography>
      </div>
    </Card>
  );
}
