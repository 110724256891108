import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Container,
  Hidden,
  CardMedia,
  CardContent,
  Card,
  CardActionArea,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    height: "30%",
    padding: "0.7%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  banner: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      minWidth: 100,
    },
    flex: 5,
    backgroundPosition: "left",
    minHeight: 150,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 25,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
    },
    alignSelf: "center",
  },
  cardAction: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Slider(props) {
  const classes = useStyles();
  const { title, text, image, link, bgColor, textColor } = props;
  const cursor = link ? "pointer" : "default";
  return (
    <Link to={link ? link : ""} className={"text-link"}>
      <Card
        className={classes.card}
        style={{ backgroundColor: bgColor, color: textColor, cursor: cursor }}
      >
        <CardMedia
          className={classes.banner}
          image={image}
          alt="slider image"
        ></CardMedia>
        <div className={classes.textContainer}>
          <Typography
            component={"span"}
            variant="h4"
            gutterBottom
            color="inherit"
          >
            <Box fontWeight="fontWeightMedium">{title}</Box>
          </Typography>
          <Typography
            component={"span"}
            variant="body1"
            gutterBottom
            color="inherit"
          >
            <Box lineHeight={1.2} fontWeight="fontWeightMedium">
              {text}
            </Box>
          </Typography>
        </div>
        <div className={classes.cardAction} aria-label="Go to slider action">
          {link ? <ChevronRight fontSize="large" color="inherit" /> : ""}
        </div>
      </Card>
    </Link>
  );
}
