import React from "react";
import Header from "components/Header/Header.js";
import Logo from "components/Header/Logo.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import { NextSeo } from "next-seo";
import CssBaseline from "@material-ui/core/CssBaseline";

function Layout({ children }) {
  return (
    <div>
      <CssBaseline />
      <Header
        color="transparent"
        brand={<Logo />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 1,
          color: "white",
        }}
      />
      {/* <NextSeo
        title="Abcvuurwerk vuurwerk online winkel"
        description="Het juiste adres voor vuurwerk met korting. Abc Vuurwerk brengt u het beste vuurwerk van arkel en vianen locaties tegen de meest betaalbare prijzen. Wij zijn de cakebox specialist van de regio."
      /> */}
      {children}
    </div>
  );
}

export default Layout;
