import React, { useEffect, useState } from "react";
import { fetchAPI } from "lib/api";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Store } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Slider from "components/Slider/Slider.js";
import SliderSkeleton from "components/Slider/SliderSkeleton.js";
import Layout from "components/Layout.js";
import Footer from "components/Footer/Footer.js";
import { Box, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import { getStrapiMedia } from "lib/media.js";

import { container, title } from "components/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    ...container,
    zIndex: "12",
    color: "#FFFFFF",
    paddingTop: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
    },
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  item: {
    paddingBottom: 5,
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [apiData, setApiData] = useState({
    sliders: [],
    homepage: [],
    backgroundImage: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function FetchData() {
      setIsLoading(true);
      const sliders = await fetchAPI("/sliders");
      const homepage = await fetchAPI("/homepage");
      const backgroundImage = await getStrapiMedia(homepage.backgroundImage);
      setApiData({
        sliders: sliders,
        homepage: homepage,
        backgroundImage: backgroundImage,
      });
      setIsLoading(false);
    }
    FetchData();
  }, []);

  return (
    <Layout>
      <Parallax filter image={apiData.backgroundImage}>
        <div className={classes.container}>
          {isLoading ? (
            <div>
              <div className={classes.item}>
                <SliderSkeleton />
              </div>
              <div className={classes.item}>
                <SliderSkeleton />
              </div>
            </div>
          ) : (
            apiData?.sliders?.map((slider) => {
              const imageUrl = getStrapiMedia(
                slider.sliderImage.formats.medium
              );
              const link = slider.article?.slug
                ? `news/${slider.article.slug}`
                : null;
              return (
                <div className={classes.item} key={slider.id}>
                  <Slider
                    title={slider.sliderTitle}
                    text={slider.sliderDescription}
                    image={imageUrl}
                    link={link}
                    bgColor={slider?.backgroundColor}
                    textColor={slider?.textColor}
                  />
                </div>
              );
            })
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} className={classes.item}>
              <Button
                fullWidth
                color="twitter"
                size="lg"
                href="https://arkel.abcvuurwerk.nl/webshop"
                rel="noopener noreferrer"
                aria-label="Ga naar Arkel Webshop"
                rightIcon={<ChevronRight />}
              >
                <Store />
                <Typography variant="button" display="block" color="primary">
                  <Box fontWeight="fontWeightBold">Ga naar Arkel Webshop</Box>
                </Typography>
              </Button>
            </GridItem>
          </GridContainer>
          <div
            // className={classes.container}
            style={{
              zIndex: "999999",
              position: "relative",
              bottom: 0,
            }}
          >
            <Footer />
          </div>
        </div>
      </Parallax>
    </Layout>
  );
};

// export async function getStaticProps() {
//   // Run API calls in parallel
//   const [articles, sliders, homepage] = await Promise.all([
//     fetchAPI("/articles"),
//     fetchAPI("/sliders"),
//     fetchAPI("/homepage"),
//   ]);

//   return {
//     props: { articles, sliders, homepage },
//     revalidate: 1,
//   };
// }

export default HomePage;
