/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @material-ui/icons
import { Store, Facebook, Instagram } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "components/Header/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/" className={"text-link"}>
          <a className={classes.navLink}>Home</a>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a
          href="https://arkel.abcvuurwerk.nl/webshop/"
          rel="noopener"
          className={classes.navLink}
        >
          Webshop
        </a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/news" className={"text-link"}>
          <a className={classes.navLink}>Nieuws</a>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={isMobile ? "left" : "top"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/abcvuurwerk/"
            target="_blank"
            rel="noopener"
            className={classes.navLink}
          >
            <Facebook />
            {isMobile ? "/abcvuurwerk" : ""}
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={isMobile ? "left" : "top"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/abcvuurwerk/"
            rel="noopener"
            target="_blank"
            className={classes.navLink}
          >
            <Instagram />
            {isMobile ? "/abcvuurwerk" : ""}
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
