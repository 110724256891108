import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import CustomTheme from "lib/CustomTheme";

// pages for this product
import HomePage from "pages/index.js";
import NewsPage from "pages/news.js";
import ArticlePage from "pages/article.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <CssBaseline />
    <Switch>
      <ThemeProvider theme={CustomTheme}>
        <Route path="/news/:slug" children={<ArticlePage />} />
        <Route exact path="/news" component={NewsPage} />
        <Route exact path="/" component={HomePage} />
      </ThemeProvider>
    </Switch>
  </Router>,
  document.getElementById("root")
);
