import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Link } from "@material-ui/core";

import ReactMarkdown from "react-markdown";
import { fetchAPI, getStrapiURL } from "lib/api";
import { getStrapiMedia } from "lib/media";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Layout from "components/Layout.js";
import Footer from "components/Footer/Footer.js";

// import queryString from "query-string";

import { container, title } from "components/theme.js";

const styles = (theme) => ({
  container: {
    ...container,
    zIndex: "12",
    color: "#FFFFFF",
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    ...title,
    color: "#FFFFFF",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("md")]: {
      margin: "-60px 5px 0px",
    },
  },
  postContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStandart: {
    textAlign: "center",
    color: "#3c4858",
  },
  textContainer: {
    ...container,
    zIndex: "12",
    paddingTop: 50,
    paddingBottom: 50,
  },
});

const useStyles = makeStyles(styles);

const Article = () => {
  const classes = useStyles();
  // const router = useRouter();
  let { slug } = useParams();

  // const query = queryString.parse(router.asPath.split(/\?/)[1]);

  const [apiData, setApiData] = useState({
    article: [],
    backgroundImage: "",
  });

  useEffect(() => {
    async function FetchData() {
      const article = await fetchAPI(`/articles/${slug}`);
      const backgroundImage = await getStrapiMedia(article.image);
      setApiData({
        article: article,
        backgroundImage: backgroundImage,
      });
    }

    // if (!id) {
    //   router.push("/news");
    // } else {
    FetchData();
    // }
  }, []);

  //   const backgroundImage = getStrapiMedia(article.image);

  return (
    <Layout>
      <Parallax
        filter
        image={apiData.backgroundImage}
        mobilePositionLeft
        responsive
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                color={"primary"}
              >
                {apiData.article.title}
              </Typography>
              <Typography variant="h6" gutterBottom color={"primary"}>
                {apiData.article.description}
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.textContainer}>
          <Typography variant="body2" component={"span"} color="secondary">
            <ReactMarkdown
              source={apiData.article.content}
              unwrapDisallowed={true}
              transformImageUri={(uri) =>
                uri.startsWith("http") ? uri : `${getStrapiURL()}${uri}`
              }
            />
          </Typography>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

// export async function getStaticPaths() {
//   const articles = await fetchAPI("/articles");

//   return {
//     paths: articles.map((article) => ({
//       params: {
//         slug: article.slug,
//       },
//     })),
//     fallback: true,
//   };
// }

// export async function getStaticProps({ params }) {
//   const article = await fetchAPI(`/articles/${params.slug}`);

//   return {
//     props: { article: article },
//     revalidate: 1,
//   };
// }

export default Article;
