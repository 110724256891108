import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Layout from "components/Layout.js";
import Footer from "components/Footer/Footer.js";
import Article from "components/Article.js";

import { fetchAPI } from "../lib/api";
import { getStrapiMedia } from "lib/media.js";

import { container, title } from "components/theme.js";

const styles = (theme) => ({
  container: {
    ...container,
    zIndex: "12",
    color: "#FFFFFF",
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    ...title,
    color: "#FFFFFF",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("md")]: {
      margin: "-60px 5px 0px",
    },
  },
  postContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStandart: {
    textAlign: "center",
    color: "#3c4858",
  },
  textContainer: {
    ...container,
    zIndex: "12",
    paddingTop: 50,
    paddingBottom: 50,
  },
});

const useStyles = makeStyles(styles);

const News = () => {
  const classes = useStyles();
  const [apiData, setApiData] = useState({
    articles: [],
    newspage: [],
    backgroundImage: "",
  });

  useEffect(() => {
    async function FetchData() {
      const articles = await fetchAPI("/articles");
      const newspage = await fetchAPI("/newspage");
      const backgroundImage = await getStrapiMedia(newspage.backgroundImage);
      setApiData({
        articles: articles,
        newspage: newspage,
        backgroundImage: backgroundImage,
      });
    }
    FetchData();
  }, []);

  return (
    <Layout>
      <Parallax
        filter
        image={apiData.backgroundImage}
        mobilePositionLeft
        responsive
        smallarImage
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  fontSize: 53,
                  fontFamily: "Roboto Slab, Times New Roman, serif",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {apiData.newspage.newsTitle}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.textContainer}>
          {apiData.articles.map((article) => {
            const imageUrl = getStrapiMedia(article.image);
            const link = article.slug ? `news/${article.slug}` : null;
            return (
              <div className={classes.item}>
                <Article
                  title={article.title}
                  description={article.description}
                  image={imageUrl}
                  link={link}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default News;

// export async function getStaticProps() {
//   // Run API calls in parallel
//   const [articles, newspage] = await Promise.all([
//     fetchAPI("/articles?_sort=published_at:desc"),
//     fetchAPI("/newspage"),
//   ]);

//   return {
//     props: { articles, newspage },
//     revalidate: 1,
//   };
// }
