/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { container } from "components/theme.js";

const styles = (theme) => ({
  ...container,
  logoImage: {
    cursor: "pointer",
    width: "150px",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
  },
});

const image = "/img/abcvuurwerk-logo-red.png";

const useStyles = makeStyles(styles);

export default function Logo() {
  const classes = useStyles();
  return (
    <Link to="/">
      <img
        src={image}
        alt="abcvuurwerk_logo"
        className={classes.logoImage}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </Link>
  );
}
